import React, { useContext, Fragment } from "react"
import { graphql } from "gatsby"

/* Import Global Context(s) */
import ProjectsContext from "~context/projects"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"
import ProjectsTable from "~components/projectsTable/projectsTable"

/* Import Local Style(s) */
import "./upcoming-projects.css"

const UpcomingProjects = ({ data: { sanityUpcoming: { upcomingProjectsDescription } } }) => {
  const [projects] = useContext(ProjectsContext)

  const filteredProjects = projects.filter(project => {
    return project.upcomingProject
  })

  return (
    <Fragment>
      <SEO title={`Upcoming Projects`} />
      <Page title="projects">
        {/* keyword */}
        <div className="filter">
          <div className="filter-selected">
            <div className="type">upcoming projects</div>
          </div>
          <div className="description">
            <p>{ upcomingProjectsDescription }</p>
          </div>
        </div>
        {/* keywords */}
        <ProjectsTable projects={filteredProjects} />
      </Page>
    </Fragment>
  )
}

export default UpcomingProjects


export const query = graphql`
  query UpcomingProjectsPageQuery {
    sanityUpcoming(_id: {eq: "upcoming"}) {
      upcomingProjectsDescription
    }
  }
`
